import axios from "axios";

export const GetBalanceSheet = async () => {
  try {
    return await axios.get("/reports/balancesheet");
  } catch (error) {
    return error;
  }
};

export const GetBalanceSheetFilters = async (payload) => {
  try {
    return await axios.get(`/reports/balancesheet`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const ExportBalanceSheet = async (payload) => {
  try {
    return await axios.get(`/reports/csv/balancesheet`, { params: payload });
  } catch (error) {
    return error;
  }
};

//Gst Export
export const ExportGstVatReportCsv = async (payload) => {
  try {
    return await axios.get(`/reports/csv/gst_vat_report`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const GetProfitAndLoss = async () => {
  try {
    return await axios.get("/reports/incomestmt");
  } catch (error) {
    return error;
  }
};

export const ExportProfitAndLoss = async (payload) => {
  try {
    return await axios.get(`/reports/csv/incomestmt`, { params: payload });
  } catch (error) {
    return error;
  }
};

// export const DownloadReports = async (payload) => {
//   try {
//     return await axios.get(`/reports/download/${payload}`);
//   } catch (error) {
//     return error;
//   }
// };

export const GetProfitAndLossFilters = async (payload) => {
  try {
    return await axios.get(`/reports/incomestmt`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const GetCashFlow = async () => {
  try {
    return await axios.get("/reports/cashflowstmt");
  } catch (error) {
    return error;
  }
};

export const GetCashFlowFilters = async (payload) => {
  try {
    return await axios.get(`/reports/cashflowstmt`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const ExportCashFlow = async (payload) => {
  try {
    return await axios.get(`/reports/csv/cashflowstmt`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const GetTrialBalance = async () => {
  try {
    return await axios.get("/reports/trialbalance");
  } catch (error) {
    return error;
  }
};

export const GetTrialBalanceFilters = async (payload) => {
  try {
    return await axios.get(`/reports/trialbalance`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const ExportTrialBalance = async (payload) => {
  try {
    return await axios.get(`/reports/csv/trialbalance`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const GetGeneralLedger = async () => {
  try {
    return await axios.get("/reports/generalledger");
  } catch (error) {
    return error;
  }
};

export const GetGeneralFilters = async (payload) => {
  try {
    return await axios.get(`/reports/generalledger`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const GetFinancialReport = async () => {
  try {
    return await axios.get(`/reports/finance_report`);
  } catch (error) {
    return error;
  }
};
export const GetFinancialReportFilters = async (payload) => {
  try {
    return await axios.get(`/reports/finance_report`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const ExportFinancialReport = async (payload) => {
  try {
    return await axios.get(`/reports/csv/finance_report`, { params: payload });
  } catch (error) {
    return error;
  }
};


export const GetGstVatReport = async () => {
  try {
    return await axios.get(`/reports/gst_vat_report`);
  } catch (error) {
    return error;
  }
};

export const GetGstVatReportFilters = async (payload) => {
  try {
    return await axios.get(`/reports/gst_vat_report`, { params: payload });
  } catch (error) {
    return error;
  }
};

export const ExportFinancialReportpdf = async (payload) => {
  try {
    return await axios.get(`/reports/pdf/finance_report`, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};

export const ExportGeneralLedger = async (payload) => {
  try {
    return await axios.get(`/reports/csv/generalledger`, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};

export const ExportPDFBalanceSheet = async (payload) => {
  try {
    return await axios.get(`/reports/pdf/balancesheet`, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};
export const ExportPDFProfitAndLoss = async (payload) => {
  try {
    return await axios.get(`/reports/pdf/incomeStmt`, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};
export const ExportPDFCashFlow = async (payload) => {
  try {
    return await axios.get(`/reports/pdf/cashFlow`, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};

export const exportPDFGstVatFn = async (payload) => {
  try {
    return await axios.get(`/reports/pdf/gst_vat_report`, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};
export const ExportPDFTrialBalance = async (payload) => {
  try {
    return await axios.get(`/reports/pdf/trialBalance`, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};
export const ExportPDFGeneralLedger = async (payload) => {
  try {
    return await axios.get(`/reports/pdf/generalLedger`, {
      params: payload,
    });
  } catch (error) {
    return error;
  }
};
