<template>
  <b-row
    class="
      d-flex
      flex-row
      align-items-start
      justify-content-start
      px-3
      w-100
      table-container-row
    "
    no-gutters
  >
    <!-- header row  -->
    <b-row
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-start
        bg-main-green
        py-3
        px-3
        w-100
      "
      no-gutters
    >
      <!-- Accounts column -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-start
          justify-content-center
          px-0
          py-2 py-lg-0
        "
        cols="6"
      >
        <h6 class="text-left text-uppercase font-weight-normal text-white">
          CASH INFLOW AND OUTFLOW
        </h6>
      </b-col>
      <!-- date column -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-end
          justify-content-center
          px-0
          py-2 py-lg-0
        "
        cols="6"
      >
        <h6
          class="text-left text-normal font-weight-normal text-white"
          v-if="startDate && endDate"
        >
          {{ startDate }} to {{ endDate }}
        </h6>
      </b-col>
    </b-row>
    <!-- detail row  -->
    <b-row
      v-for="account in cashFlow.inflowoutflow"
      :key="account.title"
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-start
        py-1
        w-100
      "
      no-gutters
    >
      <b-row class="bg-light py-3 px-3 w-100" no-gutters>
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-bold text-dark">
            {{ account.title }}
          </h6>
        </b-col>
      </b-row>
      <!-- detail row  -->
      <b-row
        class="py-1 my-2 px-3 w-100"
        no-gutters
        v-for="category in account.categories"
        :key="category.title"
      >
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-bold text-main-green">
            {{ category.title }}
          </h6>
        </b-col>
        <!-- date column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-end
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left text-normal font-weight-normal text-dark">
            {{ currency }} {{ getFormattedNumbers(category.total) }}
          </h6>
        </b-col>
      </b-row>
      <!-- total row  -->
      <b-row class="py-3 my-2 px-3 w-100" no-gutters>
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-bold text-dark">
            Net Cash from {{ account.title }}
          </h6>
        </b-col>
        <!-- date column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-end
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left text-normal font-weight-normal text-dark">
            {{ currency }} {{ getFormattedNumbers(account.total) }}
          </h6>
        </b-col>
      </b-row>
    </b-row>
    <!-- header row  -->
    <b-row
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-start
        bg-main-green
        py-3
        px-3
        w-100
      "
      no-gutters
    >
      <!-- Accounts column -->
      <b-col
        class="
          d-flex
          flex-column
          align-items-start
          justify-content-center
          px-0
          py-2 py-lg-0
        "
        cols="6"
      >
        <h6 class="text-left text-uppercase font-weight-normal text-white">
          OVERVIEW
        </h6>
      </b-col>
    </b-row>
    <!-- detail row  -->
    <b-row
      v-for="account in cashFlow.overview"
      :key="account.title"
      class="
        d-flex
        flex-row
        align-items-center
        justify-content-start
        py-1
        w-100
      "
      no-gutters
    >
      <b-row class="bg-light py-3 px-3 w-100" no-gutters>
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-bold text-dark">
            {{ account.title }}
          </h6>
        </b-col>
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-end
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-bold text-dark">
            {{ getFormattedNumbers(account.total) }}
          </h6>
        </b-col>
      </b-row>
      <!-- detail row  -->
      <b-row
        class="py-1 my-2 px-3 w-100"
        no-gutters
        v-for="category in account.categories"
        :key="category.title"
      >
        <!-- Accounts column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left font-weight-bold text-primary">
            {{ category.title }}
          </h6>
        </b-col>
        <!-- date column -->
        <b-col
          class="
            d-flex
            flex-column
            align-items-end
            justify-content-center
            px-0
            py-2 py-lg-0
          "
          cols="6"
        >
          <h6 class="text-left text-normal font-weight-normal text-dark">
            {{ getFormattedNumbers(category.total) }}
          </h6>
        </b-col>
      </b-row>
    </b-row>
  </b-row>
</template>

<script>
import { formatNumbers } from "@/util/formatNumbers.js";
import {GetCurrentWorkspace} from "@/services/settingsbusiness.service";

// services
export default {
  props: {
    cashFlowData: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  name: "CashFlowDetails",
  components: {},
  data() {
    return {
      cashFlow: {
        inflowoutflow: {
          operating: {
            title: "Operating Activities",
            total: this.currency+" 3,000.00",
            categories: [
              {
                title: "Sales",
                total: this.currency+" 1,000.00",
                accounts: [
                  {
                    title: "Sales",
                    total: this.currency+" 400.00",
                  },
                ],
              },
              {
                title: "Purchases",
                total: this.currency+" 1,000.00",
              },
              {
                title: "Inventory",
                total: this.currency+" 1,000.00",
              },
              {
                title: "Payroll",
                total: this.currency+" 1,000.00",
              },
              {
                title: "Sales Taxes",
                total: this.currency+" 1,000.00",
              },
              {
                title: "Other",
                total: this.currency+" 1,000.00",
              },
            ],
          },
          investing: {
            title: "Investing Activities",
            total: this.currency+" 3,000.00",
            categories: [
              {
                title: "Property, Plant, Equipment",
                total: this.currency+" 1,000.00",
              },
              {
                title: "Other",
                total: this.currency+" 1,000.00",
              },
            ],
          },
          financing: {
            title: "Financing Activities",
            total: this.currency+" 3,000.00",
            categories: [
              {
                title: "Loans and Lines of Credit",
                total: this.currency+" 1,000.00",
              },
              {
                title: "Owners and Shareholders",
                total: this.currency+" 1,000.00",
              },
              {
                title: "Other",
                total: this.currency+" 1,000.00",
              },
            ],
          },
        },
        overview: {
          startingBalance: {
            title: "Starting Balance",
            total: this.currency+" 3,000.00",
            categories: [
              //   {
              //     title: "Cash on Hand",
              //     total: "LKR 1,000.00",
              // },
            ],
          },
          grossCashInflow: {
            title: "Gross Cash Inflow",
            total: this.currency+" 3,000.00",
          },
          grossCashOutflow: {
            title: "Gross Cash Outflow",
            total: this.currency+" 3,000.00",
          },
          netCashChange: {
            title: "Net Cash Change",
            total: this.currency+" 3,000.00",
          },
          endingBalance: {
            title: "Ending Balance",
            total: this.currency+" 1,000.00",
            categories: [
              //   {
              //     title: "Cash on Hand",
              //     total: "LKR 1,000.00",
              // },
            ],
          },
        },
      },
      currency:localStorage.getItem('CURRENCY')
    };
  },
  watch: {
    cashFlowData(val) {
      if (val) this.initFn(val);
    },
  },
  async mounted() {
    // await this.loadCurrentWorkspace();
    this.$emit("reloadFn");
  },
  methods: {
    async loadCurrentWorkspace() {
      let {data} = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    initFn(val) {
      const {
        cashInflowOutflow: {
          operatingActivities,
          investingActivities,
          financingActivities,
        },
        overview: {
          startingBalance,
          grossCashInflow,
          grossCashOutflow,
          netCashChange,
          endingBalance,
        },
      } = val;

      // cashInflowOutflow
      this.cashFlow.inflowoutflow.operating = operatingActivities;
      this.cashFlow.inflowoutflow.investing = investingActivities;
      this.cashFlow.inflowoutflow.financing = financingActivities;

      // overview
      this.cashFlow.overview.startingBalance.total = startingBalance;
      this.cashFlow.overview.grossCashInflow.total = grossCashInflow;
      this.cashFlow.overview.grossCashOutflow.total = grossCashOutflow;
      this.cashFlow.overview.netCashChange.total = netCashChange;
      this.cashFlow.overview.endingBalance.total = endingBalance;
    },
    getFormattedNumbers(text) {
      if (!text) return "0.00";
      return formatNumbers(text);
    },
  },
};
</script>

<style scoped>
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}

/* table question action buttons */
/* edit icon button (default state) */
.table-container-row .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .edit-btn > .edit-icon {
  color: var(--main-green);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-container-row .edit-btn:hover {
  background-color: var(--main-green);
}

.table-container-row .edit-btn:hover > .edit-icon {
  color: var(--white);
}

/* delete icon button (default state) */
.table-container-row .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}

.table-container-row .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}

/* delete icon button (hover state) */
.table-container-row .delete-btn:hover {
  background-color: var(--danger);
}

.table-container-row .delete-btn:hover > .delete-icon {
  color: var(--white);
}

/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .table-card-row .filter-dropdown,
  .table-card-row .export-dropdown {
    min-width: auto;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /* .insight-cards-row {
    height: auto;
  } */
}
</style>
