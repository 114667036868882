import { render, staticRenderFns } from "./ProfitLossSummary.vue?vue&type=template&id=05c2885e&scoped=true"
import script from "./ProfitLossSummary.vue?vue&type=script&lang=js"
export * from "./ProfitLossSummary.vue?vue&type=script&lang=js"
import style0 from "./ProfitLossSummary.vue?vue&type=style&index=0&id=05c2885e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c2885e",
  null
  
)

export default component.exports