<template>
    <b-modal
   
    id="asset-deatls-modal"
    ref="assetDetailsModal"
    title="Fixed Assets Details"
    size="lg"
    header-class="font-primary"
    header-bg-variant="light"
    header-text-variant="main-green"
    header-border-variant="white"
    footer-bg-variant="transparent"
    footer-border-variant="white"
    no-close-on-esc
    no-close-on-backdrop
    @hide="closeModal()"
    hide-footer
   
  >
    <template v-slot:modal-title>
      <template>
        <span class="ml-4 text-main-green">Fixed Assets Details</span>
      </template>
 
    </template>
    <template v-slot:modal-footer="">
   
      <div
        class="d-flex flex-column flex-sm-row align-items-end justify-content-end mx-0 mb-3 w-100"
      >
        
        
        <b-button
          variant="main-green"
          pill
          class="my-2 my-sm-0 ml-0 border-0 action-btn"
          style="width: 110px;"
          @click="submit()"
        >
        Update
        </b-button>
      </div>
    </template>
    <!-- custom close icon -->
    <template v-slot:modal-header-close>
      <b-icon
        icon="x-circle"
        class="text-dark"
        @click="closeModal()"
        aria-label="Close Modal"
        font-scale="0.8"
      ></b-icon>
    </template>
    <b-row
      class="d-flex flex-row align-items-start justify-content-start w-100 py-1 mb-3 mb-md-2"
      :class=" 'mt-3'"
      no-gutters
    >
    <b-row
        class="d-flex flex-row align-items-start justify-content-start w-100 pb-2 px-3"
        no-gutters
      >
      
                <b-table
                  ref="questionsTable"
                  :bordered="false"
                  :borderless="true"
                  :hover="false"
                  show-empty
                  :small = "true"
                  class="table-element"
                  thead-class="table-header"
                 
                  :busy="isLoading"
                  :per-page="perPage"
                  :fields="customersHeader"
                  :items="assetItems"
                  @row-clicked="onTableRowClicked"
                >
                  <template #cell(Attributes)="data">
                    <b-col
                      class="d-flex flex-column align-items-start justify-content-center px-0"
                      cols="6"
                    >
                      <span class="main-text-Details " >{{ data.value }}</span>
                    </b-col>
                  </template>
                  <template #cell(AssetDetails)="data">
                    <b-col
                      class="d-flex flex-column align-items-start justify-content-center px-0"
                      cols="6"
                    >
                      <span class="main-text-customer " style="color: #828282;">{{ data.value }}:</span>
                    </b-col>
                  </template>
                </b-table>
             
      </b-row>
      
  </b-row>  
  <CreateAssetModal
        :itemContent= null
        :isEdit = true
       
        />
    </b-modal>
    
</template>

<script>

import { GetAssetDetails} from "@/services/fixedassets.service";
import CreateAssetModal from "@/components/Transactions/Modals/CreateAssetModal";

export default {
  name: "AddTransactionModal",
  props: {
    
    assetId: {
      default: null,
      required: true,
    },
    
  },
  data() {
    return {
      
      searchTable: null,
      isLoading: false,
      customersHeader: [
        {
          key: "AssetDetails",
          label: "",
        },
        {
          key: "Attributes",
          label: "",
        },
      ],
      assetItems: [
     
      ],
 
     
    }
  },
  components: { 
      CreateAssetModal,
     
    },
  watch: {
    assetId: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.onModalShow();
        }
      },
    },
  },
  
  
  methods:{

   async onModalShow(){
    console.log("Asset Id", this.assetId);
    try {
     
    //  let payload = {
    //    id: this.assetId,
    //  };
     this.isLoading = true;
     console.log("ItemContent", this.assetId);
     
     let { data } = await GetAssetDetails({id: this.assetId});
     console.log("data", data);
   
     this.assetItems = [
     { AssetDetails:"Asset name ",
       Attributes: data.name

       },
      {
         AssetDetails:"Asset number ",
       Attributes: data.number
       },
       { AssetDetails:"Asset type ",
       Attributes: data.type

       },
       { AssetDetails:"Purchased date ",
       Attributes: this.formatDate(data.purchased_date) 

       },
       { AssetDetails:"Purchased price ",
       Attributes: data.purchased_price

       },
       { AssetDetails:"Book value ",
       Attributes: data.book_value

       },
       { AssetDetails:"Serial number",
       Attributes: data.serial_number

       },
       { AssetDetails:"Warranty expiry",
       Attributes:data.warrenty_expiry

       },
       { AssetDetails:"Description",
       Attributes:data.discription

       },
       { AssetDetails:"Chart of Accounts selection",
       Attributes:data.asset_account

       },
       { AssetDetails:"Depreciation start date",
       Attributes: this.formatDate(data.depreciation_start_date)  

       },
       { AssetDetails:"Depreciation method",
       Attributes:data.depreciation_method

       },
       { AssetDetails:"Averaging method",
       Attributes: data.monthly

       },
       { AssetDetails:"Averaging Rate",
       Attributes:data.averaging_method_rate

       },
       { AssetDetails:"Effective life in years",
       Attributes: data.effective_life_in_years

       },
       { AssetDetails:"Location code",
       Attributes:data.location_code

       },
       { AssetDetails:"Departments",
       Attributes: data.department

       },
       
     ],

     this.isLoading = false;
   }catch (error) {
       this.isLoading = false;
       console.log(error);
       
     }
   },

   
   formatDate(val) {
        if (val) {
          const dateObj = new Date(val);
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      return `${day}/${month}/${year}`;
      }
   
  },
  submit(){
    this.$bvModal.hide("asset-deatls-modal");

    this.$root.$emit("bv::show::modal", `create-asset-modal`);
  }
}
  
}
</script>
<style scoped>
.types {
  width: 215px !important;
  margin: 0 20px !important;
}
::placeholder {
  color: #0b9b7d;
  opacity: 1;
}
.icon-color {
  color: #1c72ff;
}

.text-color {
  color: #1c72ff;
}

.border-color {
  border: 1px solid #1c72ff;
}

.date-fields-style {
  border: 1.8px solid #0b9b7d;
}

.desWidth {
  width: 80%;
}

.count-style {
  border-radius: 30px;
  width: 30px;
  height: 30px;
  color: #0b9b7d;
  background-color: #f7fbfc;
  font-weight: 500;
  margin-top: -1px;
  padding-left: 10px;
  padding-top: 2px;
}

.disabled-button {
  background-color: #0b9b7d;
  background: rgba(33, 150, 83, 0.5);
  color: #ffffff;
  border-color: #ffffff;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.date-picker-btn {
  border: 1.8px solid;
  border-color: #0b9b7d;
  border-radius: 0px 3px 3px 0px;
}

.account-dropdown {
  max-height: 240px;
  overflow: auto;
  scrollbar-width: thin;
}

.account-dropdown::-webkit-scrollbar {
  width: 6px;
}

.account-dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  border-radius: 5px;
}

.account-dropdown::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #0b9b7d;
}

/* table card */
.table-card-row {
  height: 100%;
}

/* .table-card-row .type-filter {
  width: 100%;
} */
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 400px;
  border-radius: 0;
  border: 1px solid var(--light);
}

/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
height: 50px;
min-width: 200px;
}

.table-card-row .search-input-group .search-input {
  box-shadow: none;
height: 50px;
}

.table-card-row .search-input-group .search-input::placeholder {
  color: #0b9b7d;
}

.table-card-row .category-dropdown,
.table-card-row .types-dropdown,
.table-card-row .accounts-dropdown,
.table-card-row .date-picker,
.table-card-row .add-question-btn {
  height: 45px;
  /* width: 100px; */
}

.table-card-row .add-question-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  color: #ffffff;
  border: none;
}

.table-card-row .accounts-dropdown {
  min-width: 295px;
}

.table-card-row .category-dropdown {
  min-width: 310px;
  padding-left: 15px;
}

.table-card-row .types-dropdown {
  min-width: 310px;
  /* margin-right: 30px; */
}

.table-card-row .startdate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
  color: #0b9b7d;
}

.table-card-row .enddate-picker {
  width: 200px;
  border: 1.8px solid #0b9b7d;
}

/* date picker */
.table-card-row .b-form-datepicker.form-control:focus {
  color: #0b9b7d;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

/* date selector calendar dialog */
.table-card-row
  .b-form-datepicker
  >>> .custom-calendar-dropdown
  .b-calendar-grid.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #0b9b7d;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(33, 150, 83, 0.5);
}

.table-card-row .category-dropdown .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row .types-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}

.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}

/* table container */
.table-container-row {
  height: 100%;
  width: 50%;
  overflow-y: hidden;
  scrollbar-width: thin;
}

.table-card-row .apply-filters-btn {
  transition: color 200ms ease-in;
  /* color: var(--secondary); */
  /* border: none; */
}

.table-card-row .apply-filters-btn:hover {
  background-color: #0b9b7d;
  /* box-shadow: inset 0px 0px 10px #ffffff; */
  /* color: var(--primary); */
}

.clearAll-text-color {
  color: #0b9b7d;
}

.clearAll-text-color:hover {
  color: #0b9b7d;
}

.vertical-bar {
  margin-right: 19px;
  margin-left: 5px;
  margin-top: 2px;
  color: #bdbdbd;
}

.table-container-row .load-more-btn {
  transition: color 200ms ease-in;
  color: #0b9b7d;
}

.table-container-row .load-more-btn:hover {
  color: #0b9b7d;
  text-decoration: none;
}

/* .table-container-row .table-action-row {
  height: 60px;
  padding-top: 20px;
} */
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}

.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(33, 150, 83, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}

.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.table-container-row .table-element {
  min-width: 500px;
  width: 100%;
  height: auto;
  scrollbar-width: thin;

}

/* table element css */
.table-container-row .table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}

.table-container-row .table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}

/* .table-element >>> .table-body td {
  vertical-align: middle;
  
} */

.creditDebit-table .table-body td,
.creditDebit-table .table-body th {
  padding: 0px;
}
.table th, .table td {
  padding: 0;
}
.table td, .table th {
  padding: 0;
}
/* .table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
} */
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}

.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}

/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}

/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}

/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: #0b9b7d;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}

/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}

.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}

/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  border: hidden;
  /* background-color: var(--white); */
  background-color: transparent;
  transition: background-color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}

.table-element >>> .table-body .edit-btn > .reviewed-icon {
  color: var(--success);
  transition: color 200ms ease-in;
}

/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  /* background-color: var(--info); */
}

.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: #0b9b7d;
}

.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}

.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}

@media (max-width: 1346px) and (min-width: 1170px) {
  .category-field {
    max-width: 350px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1170px) and (min-width: 1118px) {
  .category-field {
    max-width: 330px;
  }

  .type-field {
    max-width: 200px;
  }
}

@media (max-width: 1118px) and (min-width: 1060px) {
  .category-field {
    max-width: 310px;
  }

  .type-field {
    max-width: 188px;
  }
}

@media (max-width: 1060px) and (min-width: 992px) {
  .category-field {
    max-width: 270px;
  }

  .type-field {
    max-width: 160px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }

  .table-card-row .category-dropdown {
    min-width: 330px;
  }

  .table-card-row .types-dropdown {
    min-width: 330px;
  }
}
</style>