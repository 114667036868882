<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- user insights row -->
      <!-- user insights row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100"
        no-gutters
      >
        <b-card
          no-body
          class="position-relative shadow-sm border-1 filter-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start  px-3  w-100"
            no-gutters
          >
            <!-- Overdue column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center "
              cols="6"
            >
              <h6 class="text-left text-truncate text-medium font-weight-bold">
                Filters
              </h6>
            </b-col>
            <!-- Upcoming payout column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <b-dropdown
                variant="white"
                toggle-class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 text-main-green border border-main-green text-main-greene rounded-lg rounded-pill export-btn"
                text="Export"
              >
                <b-dropdown-item @click="exportBalanceSheetFn"
                  >CSV</b-dropdown-item
                >
                <b-dropdown-item @click="exportPDFGstVatFn"
                  >PDF</b-dropdown-item
                >
                <!-- <b-dropdown-item 
                  >PDF</b-dropdown-item
                >-->
              </b-dropdown> 
            </b-col>
          </b-row>
          <!-- date filters row  -->
          <b-row
            class="d-flex flex-row align-items-start align-items-md-center justify-content-between mt-2 mt-sm-4  px-3 date-filter-row"
            no-gutters
          >
          <b-col
              cols="12"
              sm="2"
              md="1"
              class="mb-2 mb-md-0 align-self-center"
            >
              <span class="text-left text-dark font-primary font-weight-normal"
                >Date Range
              </span>
            </b-col>


            <b-col
              cols="12"
              sm="5"
              md="2"
              class="mb-2 mb-md-0 align-self-center mt-3"
            >
              <FormSelect
                groupId="dateRange-group"
                id="dateRange-group-input"
                class="text-prime-gray rounded-2 report-select align-self-center"
                v-model="date_range"
                :whiteBG="false"
                :options="dateRangeOptions"
                form="login-form"
              ></FormSelect>
            </b-col>
            <b-col
              cols="12"
              sm="9"
              lg="5"
              class="w-100 d-flex flex-column flex-md-row"
            >
              <b-col cols="12" md="6" lg="7" class="mb-3 mb-md-0 px-0 ">
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    :value="formatDate(startDate)"
                    type="text"
                    class="border-main-green date-picker"
                    placeholder="From"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-right"
                      v-model="startDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      nav-button-variant="main-green"
                      today-variant="main-green"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="12" md="6" lg="7" class="pl-0">
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    :value="formatDate(endDate)"
                    class="border-main-green date-picker"
                    type="text"
                    placeholder="To"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-right"
                      v-model="endDate"
                      :min="minEndDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      nav-button-variant="main-green"
                      today-variant="main-green"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-col>
            <b-col cols="0" lg="2"> </b-col>
          </b-row>
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100  px-3  "
            no-gutters
          >
            <b-col
              class="d-flex flex-row align-items-center justify-content-end py-1"
            >
              <b-link
                class="d-flex flex-column align-items-end justify-content-end bg-white border-0 text-left font-primary text-main-green font-weight-bold load-more-btn"
                pill
                @click="clearFilterFn"
                v-if="computedFilterValidations"
              >
                <span class="d-inline mx-1 py-1 mt-3">Clear all</span>
              </b-link>
            </b-col>
            <b-col
              class="d-flex flex-row align-items-end justify-content-end mb-3 mb-md-4  py-1"
            >
              <b-button
                variant="main-green"
                class="d-flex flex-column align-items-start justify-content-center border-0 action-btn"
                pill
                @click="updateReport"
              >
                <span class="d-inline mx-1 py-1">Update Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100 px-1 py-0 py-lg-1 mt-0 mt-lg-3"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 px-3 py-3 w-100">
          <!-- header row  -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-start py-3 px-3 w-100"
            no-gutters
          >
            <div class="mb-4 col-12 heading">
              <h5 class="heading-text text-center font-weight-bold">Summary</h5>
            </div>
            <div style="background: #f7fbfc" class="p-4 mb-4 col-12">
              <h5>GST/VAT Audit Report</h5>
            </div>
            <p class="px-4">GST/VAT on Income</p>
            <!-- Accounts column -->
            <b-table
              striped
              hover
              :fields="fields"
              :items="gstIncomeItems"
              ref="selectableTable"
              table
              head-variant="light"
              selectable
              show-empty
              select-mode="multi"
              class="table-element"
              thead-class="table-header"
              :stickyColumn="false"
              :responsive="true"
            >
              <template #cell(gst_vat)="data">
                <p style="color: #0b9b7d" class="text-right">
                  {{ data.value }}
                </p>
              </template>
            </b-table>
            <b-table
              striped
              hover
              :fields="fieldsListIncome"
              :stickyColumn="false"
              :responsive="true"
            ></b-table>
            <p class="px-4">GST/VAT on Expenses</p>
            <!-- Accounts column -->
            <b-table
              striped
              hover
              :fields="fields"
              :items="gstExpenseItems"
              ref="selectableTable"
              table
              head-variant="light"
              selectable
              show-empty
              select-mode="multi"
              class="table-element"
              thead-class="table-header"
              :stickyColumn="false"
              :responsive="true"
            >
              <template #cell(gst_vat)="data">
                <p style="color: #0b9b7d" class="text-right">
                  {{ data.value }}
                </p>
              </template>
            </b-table>
            <!-- <b-col class="d-flex justify-content-between">
              <div>
                <p>Total GST/VAT on Expenses</p>
              </div>
              <div class="d-flex justify-content-start">
                <div class="px-3">
                  <span>{{ totalPreTaxGstExpense }}</span>
                </div>
                <div class="px-1">
                  <span>{{ totalGstVatExpense }}</span>
                </div>
                <div style="padding-left: 32px;padding-right: 16px;">
                  <span>{{ totalGstExpenseItems }}</span>
                </div>
              </div>
            </b-col> -->
            <b-table
              striped
              hover
              :fields="fieldsListExpense"
              :stickyColumn="false"
              :responsive="true"
            ></b-table>
            <b-table
              striped
              hover
              :fields="NetDifference"
              :stickyColumn="false"
              :responsive="true"
            ></b-table>
                        <p class="px-4">Non GST/VAT on Income</p>
            <!-- Accounts column -->
            <b-table
              striped
              hover
              :fields="fields"
              :items="nonGstIncomeItems"
              ref="selectableTable"
              table
              head-variant="light"
              selectable
              show-empty
              select-mode="multi"
              class="table-element"
              thead-class="table-header"
              :stickyColumn="false"
              :responsive="true"
            >
              <template #cell(gst_vat)="data">
                <p style="color: #0b9b7d" class="text-right">
                  {{ data.value }}
                </p>
              </template>
            </b-table>
            <b-table
              striped
              hover
              :fields="nonFieldsListIncome"
              :stickyColumn="false"
              :responsive="true"
            ></b-table>

            <p class="px-4">Non GST/VAT on Expense</p>
            <!-- Accounts column -->
            <b-table
              striped
              hover
              :fields="fields"
              :items="nonGstExpenseItems"
              ref="selectableTable"
              table
              head-variant="light"
              selectable
              show-empty
              select-mode="multi"
              class="table-element"
              thead-class="table-header"
              :stickyColumn="false"
              :responsive="true"
            >
              <template #cell(gst_vat)="data">
                <p style="color: #0b9b7d" class="text-right">
                  {{ data.value }}
                </p>
              </template>
            </b-table>
            <b-table
              striped
              hover
              :fields="nonFieldsListExpense"
              :stickyColumn="false"
              :responsive="true"
            ></b-table>

          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
//utils
// import { formatNumbers } from "@/util/formatNumbers.js";

// @ is an alias to /src
// import { baseURL } from "@/services/config";
import HomeLayout from "@/layout/HomeLayout";
import FormSelect from "@/components/Form/FormSelect";
import { formatNumbers } from "@/util/formatNumbers.js";
import {
  GetGstVatReport,
  ExportGstVatReportCsv,
  GetGstVatReportFilters,
  exportPDFGstVatFn
} from "@/services/reports.service";
// services
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import { baseURL } from "@/services/config";

export default {
  name: "GSTVatReport",
  components: {
    HomeLayout,
    FormSelect,
  },
  data() {
    return {
      totalPreTaxGstExpense: 0.0,
      totalGstVatExpense: 0.0,
      totalGstExpenseItems: 0.0,
      totalPreTaxGstIncome: 0.0,
      totalGstVatIncome: 0.0,
      totalGstIncomeItems: 0.0,
      currantYear: null,
      lastYear: null,
      date_range:null,
      dateRangeOptions: ["This financial year","Last financial year","Last year Q1","Last year Q2","Last year Q3","Last year Q4"],
      slotName: "cell(year2023)",
      dataList: [],
      currency: localStorage.getItem("CURRENCY"),
      isLoading: false,
      startDate: null,
      endDate: null,
      startDateError: null,
      endDateError: null,
      reportType: null,
      isFiltersApplied: false,
      fields: [
        { key: "date", label: "DATE" },
        { key: "gl_code", label: "GL CODE" },
        { key: "invoice_no", label: "INVOICE NO" },
        { key: "description", label: "DESCRIPTION" },
        { key: "pre_tax", label: "PRE.TAX", class: "text-right" },
        { key: "gst_vat", label: "GST/VAT", class: "text-right" },
        { key: "total", label: "TOTAL", class: "text-right" },
      ],
      fieldsListExpense: [
        {
          key: "item1",
          label: "Total  GST/VAT on Expenses",
          thStyle: { width: "60%" },
        },
        { key: "item2", label: "NZD 00.00", class: "text-right" },
        { key: "item3", label: "NZD 00.00", class: "text-right" },
        { key: "item4", label: "NZD 00.00", class: "text-right" },
      ],
      fieldsListIncome: [
        {
          key: "item1",
          label: "Total  GST/VAT on Income ",
          thStyle: { width: "60%" },
        },
        { key: "item2", label: "NZD 00.00", class: "text-right" },
        { key: "item3", label: "NZD 00.00", class: "text-right" },
        { key: "item4", label: "NZD 00.00", class: "text-right" },
      ],
      nonFieldsListExpense: [
        {
          key: "item1",
          label: "Total Non GST/VAT on Expense ",
          thStyle: { width: "60%" },
        },
        { key: "item2", label: "NZD 00.00", class: "text-right" },
        { key: "item3", label: "NZD 00.00", class: "text-right" },
        { key: "item4", label: "NZD 00.00", class: "text-right" },
      ],
      nonFieldsListIncome: [
        {
          key: "item1",
          label: "Total Non GST/VAT on Income ",
          thStyle: { width: "60%" },
        },
        { key: "item2", label: "NZD 00.00", class: "text-right" },
        { key: "item3", label: "NZD 00.00", class: "text-right" },
        { key: "item4", label: "NZD 00.00", class: "text-right" },
      ],
      NetDifference: [
      {
        key: "item1",
        label: "Net Difference",
        thStyle: { width: "60%" },
      },
      { key: "item2", label: "", class: "text-right" },
      { key: "item3", label: "NZD 00.00", class: "text-right" },
      { key: "item4", label: "", class: "text-right" },
    ],
      gstExpenseItems: [],
      gstIncomeItems: [],
      nonGstExpenseItems: [],
      nonGstIncomeItems: [],
      reportTypeOptions: [
        {
          text: "Accrual (Paid & Unpaid)",
          value: "accrual",
        },
        {
          text: "Cash Basis (Paid)",
          value: "cash",
        },
      ],
      trialBalanceData: {},
    };
  },
  async mounted() {
    
    window.scrollTo(0, 0);
    await this.initFn();
    
    await this.loadCurrentWorkspace();
  },
  watch: {
    startDate(val) {
     if(val){
      this.date_range=null
     }
     
    },
    endDate(val){
     if(val){
      this.date_range=null
     }
    }
  },
  computed: {
    minEndDate() {
      return this.startDate;
    },
    computedFilterValidations() {
      if (this.reportType || this.startDate || this.endDate || this.date_range) {
        return true;
      }
      if (this.isFiltersApplied) this.clearFilterFn();
      return false;
    },
  },
  methods: {
    async exportPDFGstVatFn() {
      let payload = {
        start_date: this.startDate,
        end_date: this.endDate,
        reportType: this.reportType,
      };
      try {
        let { data, status } = await exportPDFGstVatFn(payload);
        console.log("export ", data);
        if (status == 200) {
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async exportBalanceSheetFn() {
      console.log( this.startDate,this.endDate)
      let payload = {
        start_date: this.startDate,
        end_date: this.endDate,
        reportType: this.reportType,
        date_range: this.date_range,
      };
      try {
        let { data, status } = await ExportGstVatReportCsv(payload);
        console.log("export ", data);
        if (status == 200) {
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    numberFormat(val) {
      return formatNumbers(val);
    },
    formatWothCurrencySymbol(symbol, value) {
      return `${symbol} ${this.numberFormat(value)}`;
    },
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    formatValueWithCurrencySymbol(symbol, value) {
    const formatter = new Intl.NumberFormat("en-NZ", {
      style: "currency",
      currency: symbol,
    });
    
    const formattedValue = formatter.format(value);
    
    if (value < 0) {
      return `(${symbol} ${formatter.format(Math.abs(value))})`;
    } else {
      return `${symbol} ${formattedValue}`;
    }
  },
    async initFn() {
      const res = await GetGstVatReport();
  this.gstExpenseItems = this.formatApiResponse(
    res.data.gstVat.expense.gstExpenseInvoiceItems
  );
      this.gstExpenseItems = this.formatApiResponse(
        res.data.gstVat.expense.gstExpenseInvoiceItems
      );
      //Calculate Total
      this.fieldsListExpense[1].label = this.formatWothCurrencySymbol(
        this.currency,
        res.data.gstVat.expense.totalPreTaxGstExpense
      );
      this.fieldsListExpense[2].label = this.formatWothCurrencySymbol(
        this.currency,
        res.data.gstVat.expense.totalGstVatExpense
      );
      this.fieldsListExpense[3].label = this.formatWothCurrencySymbol(
        this.currency,
        res.data.gstVat.expense.totalGstExpenseItems
      );

      this.gstIncomeItems = this.formatApiResponse(
        res.data.gstVat.income.gstIncomeInvoiceItems
      );

      //Calculate Total
      const obj = res.data.gstVat.income;
      this.fieldsListIncome[1].label = this.formatWothCurrencySymbol(
        this.currency,
        obj.totalPreTax
      );
      this.fieldsListIncome[2].label = this.formatWothCurrencySymbol(
        this.currency,
        obj.totalGst
      );
      this.fieldsListIncome[3].label = this.formatWothCurrencySymbol(
        this.currency,
        obj.total
      );
      // Non GST/VAT on Expense
      this.nonGstExpenseItems = this.formatApiResponse(
        res.data.noGst.expense.nonGstExpenseInvoiceItems
      );

      //Calculate Total
      const obj3 = res.data.noGst.expense;
      this.nonFieldsListExpense[1].label = this.formatWothCurrencySymbol(
        this.currency,
        obj3.totalPreTax
      );
      this.nonFieldsListExpense[2].label = this.formatWothCurrencySymbol(
        this.currency,
        obj3.totalGst
      );
      this.nonFieldsListExpense[3].label = this.formatWothCurrencySymbol(
        this.currency,
        obj3.total
      );
      //Non GST Income
      this.nonGstIncomeItems = this.formatApiResponse(
        res.data.noGst.income.nonGstIncomeInvoiceItems
      );

      this.NetDifference[2].label =  this.formatWothCurrencySymbol(this.currency,res.data.gstVat.gstvat_netdifference.totalGstDifference.toString())

      //Calculate Total
      const obj4 = res.data.noGst.income;
      this.nonFieldsListIncome[1].label = this.formatWothCurrencySymbol(
        this.currency,
        obj4.totalPreTax
      );
      this.nonFieldsListIncome[2].label = this.formatWothCurrencySymbol(
        this.currency,
        obj4.totalGst
      );
      this.nonFieldsListIncome[3].label = this.formatWothCurrencySymbol(
        this.currency,
        obj4.total
      );
    },
    formatApiResponse(resArray) {
      const result = resArray.map((x) => {
        return {
          date: x.createDate,
          gl_code: x.code,
          invoice_no: x.invoiceNumber,
          description: x.description,
          pre_tax: this.formatWothCurrencySymbol(this.currency, x.preTaxAmount),
          gst_vat: this.formatWothCurrencySymbol(this.currency, x.gstVatValue),
          total: this.formatWothCurrencySymbol(this.currency, x.total),
        };
      });
      return result;
    },
     clearFilterFn() {
      this.initFn();
      this.startDate = undefined;
      this.endDate = undefined;
      this.reportType = undefined;
      this.isFiltersApplied = false;
      this.date_range=null
    },
    async updateReport() {
      if (
        this.startDate ||
        this.endDate ||
        this.reportType ||
        this.date_range
      ) {
        this.startDateError = true;
        this.endDateError = true;
        let payload = {
          start_date: this.startDate,
          end_date: this.endDate,
          reportType: this.reportType,
          date_range: this.date_range,
        };
        try {
          // this.isLoading = true;
          // this.isFiltersApplied = false;
          // let { data } = await GetGstVatReportFilters(payload);
          // this.cashFlowData = data;
          // console.log("cash Flow filter", data);
          // this.isLoading = false;
          const res = await GetGstVatReportFilters(payload);
          this.gstExpenseItems = this.formatApiResponse(
            res.data.gstVat.expense.gstExpenseInvoiceItems
          );
          //Calculate Total
          this.fieldsListExpense[1].label = this.formatWothCurrencySymbol(
            this.currency,
            res.data.gstVat.expense.totalPreTaxGstExpense
          );
          this.fieldsListExpense[2].label = this.formatWothCurrencySymbol(
            this.currency,
            res.data.gstVat.expense.totalGstVatExpense
          );
          this.fieldsListExpense[3].label = this.formatWothCurrencySymbol(
            this.currency,
            res.data.gstVat.expense.totalGstExpenseItems
          );

          this.gstIncomeItems = this.formatApiResponse(
            res.data.gstVat.income.gstIncomeInvoiceItems
          );

          //Calculate Total
          const obj = res.data.gstVat.income;
          this.fieldsListIncome[1].label = this.formatWothCurrencySymbol(
            this.currency,
            obj.totalPreTax
          );
          this.fieldsListIncome[2].label = this.formatWothCurrencySymbol(
            this.currency,
            obj.totalGst
          );
          this.fieldsListIncome[3].label = this.formatWothCurrencySymbol(
            this.currency,
            obj.total
          );
          // Non GST/VAT on Expense
          this.nonGstExpenseItems = this.formatApiResponse(
            res.data.noGst.expense.nonGstExpenseInvoiceItems
          );

          //Calculate Total
          const obj3 = res.data.noGst.expense;
          this.nonFieldsListExpense[1].label = this.formatWothCurrencySymbol(
            this.currency,
            obj3.totalPreTax
          );
          this.nonFieldsListExpense[2].label = this.formatWothCurrencySymbol(
            this.currency,
            obj3.totalGst
          );
          this.nonFieldsListExpense[3].label = this.formatWothCurrencySymbol(
            this.currency,
            obj3.total
          );
          //Non GST Income
          this.nonGstIncomeItems = this.formatApiResponse(
            res.data.noGst.income.nonGstIncomeInvoiceItems
          );
          this.NetDifference[2].label = this.formatWothCurrencySymbol(this.currency, res.data.gstVat.gstvat_netdifference.totalGstDifference)
    
          //Calculate Total
          const obj4 = res.data.noGst.income;
          this.nonFieldsListIncome[1].label = this.formatWothCurrencySymbol(
            this.currency,
            obj4.totalPreTax
          );
          this.nonFieldsListIncome[2].label = this.formatWothCurrencySymbol(
            this.currency,
            obj4.totalGst
          );
          this.nonFieldsListIncome[3].label = this.formatWothCurrencySymbol(
            this.currency,
            obj4.total
          );
          // this.clearFilterFn()
        } catch (error) {
          this.isLoading = false;
          this.clearFilterFn()
          console.log(error);
        }
      } else {
        this.startDateError = false;
        this.endDateError = false;
      }
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
    
  },
};
</script>

<style scoped>
.heading {
  position: relative;
}

.heading-text::before,
.heading-text::after {
  content: "";
  display: block;
  width: 40%;
  height: 2px;
  background: black;
  position: absolute;
  top: 50%;
}
.heading-text::before {
  left: 0;
}
.heading-text::after {
  right: 0;
}
.mr-top {
  margin-top: 32px;
}
.hiddenText {
  opacity: 0;
}
.table-element >>> .table-header th {
  background: #0b9b7d;
  color: #ffffff;
}
.line-septate {
  border-bottom: 1px solid #bdbdbd;
}
/* insights card */
.export-btn {
  width: 160px;
  height: 50px;
  border: 1.8px solid #0b9b7d;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: var(--main-green);
}

.date-picker-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #0b9b7d;
  height: 38px;
}

.filter-cards-row {
  padding: 25px;
  height: 240px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid var(--main-green);
  margin-bottom: 20px;
}
.filter-cards-row .date-filter-row {
  /* width: 75%; */
}
.action-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  border: none;
}
/* table card */
.table-card-row {
  height: 100%;
}
/* .table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
} */
.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}
.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray); */
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: var(--primary);
}
.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 2px solid var(--primary);
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .filter-cards-row {
    min-height: 270px;
    padding: 20px 5px;
    margin: 20px 5px;
    width: 100%;
  }
  .filter-cards-row .date-filter-row {
    width: 100%;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .filter-cards-row {
    min-height: 360px;
    padding: 20px 0px;
    margin: 5px;
    width: 100%;
  }
  .filter-cards-row .date-filter-row {
    width: 100%;
  }
}
</style>
