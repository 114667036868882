<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- user insights row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center w-100"
        no-gutters
      >
        <b-card
          no-body
          class="position-relative shadow-sm border-1 filter-cards-row w-100"
        >
          <b-row
            class="d-flex flex-row align-items-center justify-content-start px-3 w-100"
            no-gutters
          >
            <!-- Overdue column -->
            <b-col
              class="d-flex flex-column align-items-start justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <h6 class="text-left text-truncate text-medium font-weight-bold">
                Filters
              </h6>
            </b-col>
            <!-- Upcoming payout column -->
            <b-col
              class="d-flex flex-column align-items-end justify-content-center px-0 py-2 py-lg-0"
              cols="6"
            >
              <!-- <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <b-button
                    variant="white"
                    class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 text-info border border-info rounded-lg"
                    pill
                  >
                    <span class="d-inline mx-4 py-1">Export</span>
                  </b-button>
                </template>
                <b-dropdown-item @click="exportBalanceSheetFn"
                  >Export CSV</b-dropdown-item
                >
                <b-dropdown-item>Export PDF</b-dropdown-item>
              </b-dropdown> -->
              <b-dropdown
                variant="white"
                toggle-class="d-flex flex-row align-items-center justify-content-center mx-0 my-2 my-lg-0 text-main-green border border-main-green text-main-green rounded-lg rounded-pill export-btn"
                text="Export"
              >
                <b-dropdown-item @click="exportBalanceSheetFn"
                  >CSV</b-dropdown-item
                >
                <b-dropdown-item @click="exportPDFBalanceSheetFn"
                  >PDF</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
          <!-- date filters row  -->
          <b-row
            class="d-flex flex-row align-items-start align-items-md-center justify-content-between px-3 mt-2 mt-sm-4 date-filter-row"
            no-gutters
          >
            <b-col
              cols="12"
              sm="2"
              md="1"
              class="mb-2 mb-md-0 align-self-center"
            >
              <span class="text-left text-dark font-primary font-weight-normal"
                >As
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="5"
              md="2"
              class="mb-2 mb-md-0 align-self-center mt-3"
            >
              <FormSelect
                groupId="dateRange-group"
                id="dateRange-group-input"
                class="text-prime-gray rounded-2 report-select align-self-center"
                v-model="dateRange"
                :whiteBG="false"
                :options="dateRangeOptions"
                form="login-form"
              ></FormSelect>
            </b-col>
            <b-col
              cols="12"
              sm="9"
              lg="5"
              class="w-100 d-flex flex-column flex-md-row align-self-center"
            >
              <b-col
                cols="12"
                md="6"
                lg="7"
                class="mb-3 mb-md-0 px-0 align-self-center"
              >
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    :value="formatDate(startDate)"
                    type="text"
                    class="border-main-green date-picker"
                    placeholder="Choose a date"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-right"
                      v-model="startDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      :state="startDateError"
                      nav-button-variant="main-green"
                      today-variant="main-green"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <!-- <b-col cols="12" md="6" lg="8" class="pl-0 pl-md-1">
                <b-input-group>
                  <b-form-input
                    id="example-input"
                    v-model="endDate"
                    class="border-info date-picker"
                    type="text"
                    placeholder="To"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      class="date-picker-btn font-primary datepicker align-items-center "
                      v-model="endDate"
                      :min="minEndDate"
                      no-flip
                      button-only
                      button-variant="white"
                      locale="en-US"
                      right
                      :state="endDateError"
                      nav-button-variant="info"
                      today-variant="info"
                      menu-class=" rounded-lg custom-calendar-dropdown text-prime-gray"
                      aria-controls="start-date-input"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-col> -->
            </b-col>
            <b-col cols="0" lg="2"> </b-col>
          </b-row>
          <!-- full row input (No :) -->
          <b-row
            class="d-flex flex-row align-items-start justify-content-start w-100 px-3"
            no-gutters
          >
            <b-col
              class="d-flex flex-row align-items-center justify-content-end py-1"
            >
              <b-link
                class="d-flex flex-column align-items-end justify-content-center bg-white border-0 text-left font-primary text-main-green font-weight-bold load-more-btn"
                pill
                @click="clearFilterFn"
                v-if="computedFilterValidations"
              >
                <span class="d-inline mx-1 py-1 mt-3">Clear all</span>
              </b-link>
            </b-col>
            <b-col
              class="d-flex flex-row align-items-end justify-content-end mb-3 mb-md-4 px-1 py-1"
            >
              <b-button
                variant="main-green"
                class="d-flex flex-column align-items-start justify-content-center border-0 action-btn"
                pill
                @click="updateReport"
              >
                <span class="d-inline mx-1 py-1">Update Report</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <b-row
            class="d-flex flex-row align-items-center justify-content-center mt-4 mt-md-1 mt-lg-5 px-3 w-100 summary-row"
            no-gutters
          >
            <!-- Overdue column -->
            <b-col
              class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="2"
            >
              <h6
                class="text-left text-truncate text-small font-weight-normal text-gray-750 title-text"
              >
                Cash and Bank
              </h6>
              <h5
                class="text-left font-secondary font-weight-bold text-dark mt-2 number-text"
              >
                <!-- {{ analytics.amountOverdue }} -->
                {{ getFormattedNumbers(balanceSheetData.equation.cashAndBank) }}
                {{ currency }}
              </h5>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="1"
            >
              <b-icon
                icon="plus"
                class=""
                aria-hidden="true"
                font-scale="1.8"
              ></b-icon>
            </b-col>
            <!-- Due within next 30 days column -->
            <b-col
              class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="2"
            >
              <h6
                class="text-left text-truncate text-small font-weight-normal text-gray-750 title-text"
              >
                To be received
              </h6>
              <h5
                class="text-left font-secondary font-weight-bold text-dark mt-2 number-text"
              >
                <!-- {{ analytics.monthOverdue }} -->
                {{
                  getFormattedNumbers(balanceSheetData.equation.tobeReceived)
                }}
                {{ currency }}
              </h5>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="1"
            >
              <b-icon
                icon="dash"
                class=""
                aria-hidden="true"
                font-scale="1.8"
              ></b-icon>
            </b-col>
            <!-- Average time column -->
            <b-col
              class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="2"
            >
              <h6
                class="text-left text-truncate text-small font-weight-normal text-gray-750 title-text"
              >
                To be paid out
              </h6>
              <h5
                class="text-left font-secondary font-weight-bold text-dark mt-2 number-text"
              >
                <!-- {{ analytics.avgTime }} -->
                {{ getFormattedNumbers(balanceSheetData.equation.tobePaid) }}
                {{ currency }}
              </h5>
            </b-col>
            <b-col
              class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="1"
            >
              <b-img
                src="@/assets/equal.png"
                class="px-1 img-eql"
                height="30px"
                alt="Finance"
              ></b-img>
            </b-col>
            <!-- Upcoming payout column -->
            <b-col
              class="d-flex flex-column align-items-center justify-content-center px-0 py-2 py-lg-0"
              cols="12"
              md="2"
            >
              <!-- <h6
                class="text-left text-truncate text-small font-weight-normal text-gray-750 title-text"
              >
                Net Profit
              </h6> -->
              <h5
                class="text-left font-secondary font-weight-bold text-main-green mt-2 number-text"
              >
                <!-- {{ analytics.upcomingPayout }} -->
                {{
                  getFormattedNumbers(
                    balanceSheetData.equation.cashAndBank +
                      balanceSheetData.equation.tobeReceived -
                      balanceSheetData.equation.tobePaid
                  )
                }}
                {{ currency }}
              </h5>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-center w-100 px-1 py-0 py-lg-1 mt-0 mt-lg-3"
            no-gutters
          >
            <div v-if="this.buttonStatus == true">
              <b-button
                variant="outline-main-green"
                @click="BtnclickDetailsFnc"
                pill
                class="mb-5 action-btn"
              >
                {{ this.buttonText }}</b-button
              >
            </div>
            <div v-if="this.buttonStatus == false">
              <b-button
                variant="outline-main-green"
                @click="BtnclickSummaryFnc"
                pill
                class="mb-2 mt-2 action-btn"
              >
                {{ this.buttonText }}</b-button
              >
            </div>
            <b-overlay :show="isLoading" z-index="4" no-wrap></b-overlay>
            <section v-if="this.buttonStatus == false">
              <div>
                <b-row
                  class="d-flex flex-row align-items-center justify-content-start p-4"
                  no-gutters
                >
                  <hr
                    style="
                      width: 45%;
                      text-align: left;
                      margin-left: 0;
                      border-color: black;
                    "
                  />
                  <span class="text-black text-left">Summary</span>
                  <hr
                    style="
                      width: 45%;
                      text-align: right;
                      margin-right: 0;
                      border-color: black;
                    "
                  />
                </b-row>
              </div>
              <BalanceSheetSummary
                :balanceSheetData="balanceSheetData"
                :startDate="startDate"
                :endDate="endDate"
              />
            </section>
            <section v-if="this.buttonStatus == true">
              <div>
                <b-row
                  class="d-flex flex-row align-items-center justify-content-start pb-4"
                  no-gutters
                >
                  <hr
                    style="
                      width: 45%;
                      text-align: left;
                      margin-left: 0;
                      border-color: black;
                    "
                  />
                  <span class="text-black text-left">Details</span>
                  <hr
                    style="
                      width: 45%;
                      text-align: right;
                      margin-right: 0;
                      border-color: black;
                    "
                  />
                </b-row>
              </div>
              <BalanceSheetDetails
                :balanceSheetData="balanceSheetData"
                :startDate="startDate"
                :endDate="endDate"
              />
            </section>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import { baseURL } from "@/services/config";
import HomeLayout from "@/layout/HomeLayout";
import FormSelect from "@/components/Form/FormSelect";
import BalanceSheetSummary from "@/components/Reports/BalanceSheet/BalanceSheetSummary";
import BalanceSheetDetails from "@/components/Reports/BalanceSheet/BalanceSheetDetails";

import { formatNumbers } from "@/util/formatNumbers.js";

// services
import {
  GetBalanceSheet,
  GetBalanceSheetFilters,
  ExportBalanceSheet,
  ExportPDFBalanceSheet,
} from "@/services/reports.service";
import { GetCurrentWorkspace } from "@/services/settingsbusiness.service";
import { FiscalYearRange } from "@/services/analytics.service";
export default {
  name: "BalanceSheet",
  components: {
    HomeLayout,
    FormSelect,
    BalanceSheetSummary,
    BalanceSheetDetails,
  },
  data() {
    return {
      buttonText: "Show Details",
      buttonStatus: false,
      isLoading: false,
      startDate: null,
      endDate: null,
      startDateError: null,
      endDateError: null,
      reportType: null,
      currentYear: new Date(Date.now()).getFullYear(),
      isFiltersApplied: false,
      dateRange: null,
      // dateRangeOptions: ["This financial year","Last financial year","Last year Q1","Last year Q2","Last year Q3","Last year Q4"],
      dateRangeOptions: null,
      reportTypeOptions: [
        {
          text: "Accrual (Paid & Unpaid)",
          value: "accrual",
        },
        {
          text: "Cash Basis (Paid)",
          value: "cash",
        },
      ],
      // reportTypeOptions: ["Accrual (Paid & Unpaid)", "Cash Basis (Paid)"],
      balanceSheetData: {
        equation: {
          assets: 0,
          cashAndBank: 0,
          equity: 0,
          liabilities: 0,
          tobePaid: 0,
          tobeReceived: 0,
        },
        report: {
          Assets: {
            title: "Assets",
            total: 0,
            categories: [
              {
                title: "Cash and Bank",
                total: 0,
                accounts: [],
              },
              {
                title: "Other Current Assets",
                total: 0,
                accounts: [],
              },
              {
                title: "Long-term Assets",
                total: 0,
                accounts: [],
              },
              {
                title: "Other",
                total: 0,
                accounts: [],
              },
            ],
          },
          Liabilities: {
            title: "Liabilities",
            total: 0,
            categories: [
              {
                title: "Current Liabilities",
                total: 0,
                accounts: [],
              },
              {
                title: "Long-term Liabilities",
                total: 0,
                accounts: [],
              },
            ],
          },
          Equity: {
            title: "Equity",
            total: 0,
            categories: [
              {
                title: "Retained earnings",
                total: 0,
                accounts: [
                  {
                    title: "Profit",
                    total: 0,
                  },
                ],
              },
            ],
          },
        },
      },
      currency: localStorage.getItem("CURRENCY"),
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.loadCurrentWorkspace();
    await this.initFn();
    const {
      data: { fiscal_years },
    } = await FiscalYearRange();
    this.dateRangeOptions = fiscal_years;
    const currentYear = new Date().getFullYear();
    // const startYear = currentYear - 20;
    // const endYear = currentYear + 20;
    // for (let i = startYear; i <= endYear; i++) {
    //   this.dateRangeOptions.push(i);
    // }
    this.dateRange = currentYear.toString();
  },
  watch: {
    startDate() {
      // reset the start date when end date changes
      this.endDate = "";
    },
    dateRange(val) {
      this.startDate = `${val}-12-31`;
    },
  },
  computed: {
    minEndDate() {
      return this.startDate;
    },
    computedFilterValidations() {
      const selectYear = Number(this.startDate.split("-")[0]);
      if (this.reportType || !(selectYear === this.currentYear)) {
        return true;
      }
      if (this.isFiltersApplied) this.clearFilterFn();
      return false;
    },
  },
  methods: {
    async loadCurrentWorkspace() {
      let { data } = await GetCurrentWorkspace();
      this.currency = data.currency;
    },
    async initFn() {
      try {
        this.isLoading = true;
        let { data } = await GetBalanceSheet();
        console.log("balance sheet", data);
        this.balanceSheetData = data;
        // console.log("acc", this.accounts);
        // console.log("acc", this.accounts.assets);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async updateReport() {
      if (this.startDate || this.endDate || this.reportType) {
        this.startDateError = true;
        this.endDateError = true;
        let payload = {
          endDate: this.startDate,
          reportType: this.reportType,
        };
        console.log(payload);
        try {
          this.isLoading = true;
          this.isFiltersApplied = false;
          let { data } = await GetBalanceSheetFilters(payload);
          this.balanceSheetData = data;
          console.log("balance Sheet filter", data);
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          console.log(error);
        }
      } else {
        this.startDateError = false;
        this.endDateError = false;
      }
    },
    async exportBalanceSheetFn() {
      let payload = {
        // startDate: this.startDate,
        endDate: this.startDate,
        reportType: this.reportType,
      };
      try {
        let { data, status } = await ExportBalanceSheet(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async exportPDFBalanceSheetFn() {
      let payload = {
        // startDate: this.startDate,
        endDate: this.startDate,
        reportType: this.reportType,
      };
      try {
        let { data, status } = await ExportPDFBalanceSheet(payload);
        console.log("export ", data);
        if (status == 200) {
          console.log(baseURL);
          window.open(`${baseURL}/reports/download/${data}`);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getFormattedNumbers(text) {
      if (!text) return "0.00";
      return formatNumbers(text);
    },
    BtnclickDetailsFnc() {
      this.buttonText = "Show Details";
      this.buttonStatus = false;
      console.log(this.buttonText, this.buttonStatus);
    },
    BtnclickSummaryFnc() {
      this.buttonText = "Show Summary";
      this.buttonStatus = true;
    },
    clearFilterFn() {
      this.initFn();
      this.startDate = `${this.currentYear}-12-31`;
      this.endDate = undefined;
      this.reportType = undefined;
      this.isFiltersApplied = false;
      this.dateRange = new Date().getFullYear();
      this.date_range = null;
    },
    formatDate(val) {
      if (val) {
        const dates = val.split("-");
        return `${dates[2]}/${dates[1]}/${dates[0]}`;
      }
    },
  },
};
</script>

<style scoped>
/* insights card */
.export-btn {
  width: 160px;
  height: 50px;
  border: 1.8px solid #0b9b7d;
}

.date-picker-btn >>> .btn .b-icon.bi {
  color: #0b9b7d;
}

.b-input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.b-input-group-append {
  margin-left: -1px; /* adjust as needed */
}

.date-picker-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #0b9b7d;
  height: 38px;
}

.b-form-datepicker {
  margin-left: -1px; /* adjust as needed */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.b-form-input {
  border-radius: 0 !important;
}

.filter-cards-row {
  padding: 25px;
  height: 240px;
  width: 100%;
  background-color: var(--white);
  border-radius: 5px;
  border: 1px solid #0b9b7d;
  margin-bottom: 20px;
}

.filter-cards-row .date-filter-row {
  /* width: 75%; */
}

.action-btn:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
  border: none;
}

/* table card */
.table-card-row {
  height: 100%;
}

.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 560px;
  border-radius: 5px;
  border: 1px solid var(--light);
}

.table-card-row .table-container-card >>> .tab-wrapper {
  /* border-bottom: 0.5px solid var(--gray-750); */
  border-bottom: none;
}

.table-card-row .table-container-card >>> .invoice-tabs {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  /* background-color: var(--ghost-white); */
}

.report-type {
  margin-left: -4%;
}

/*.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link {
  font-size: 0.9rem;
  padding-right: 20px;
  white-space: nowrap;
  background: transparent;
  border: transparent;
  color: var(--gray-750);
  /* border-bottom: 1px solid var(--prime-gray);
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link:hover {
  background-color: var(--light);
  color: var(--primary);
}

.table-card-row .table-container-card >>> .invoice-tabs .tab-nav-link.active {
  background-color: var(--light);
  border-bottom: 2px solid var(--primary);
}
*/
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .filter-cards-row {
    min-height: 270px;
    padding: 20px 5px;
    margin: 20px 5px;
    width: 100%;
  }

  .filter-cards-row .date-filter-row {
    width: 100%;
  }

  .table-card-row .table-container-card .summary-row {
    padding: 20px 40px;
  }

  .table-card-row .table-container-card .summary-row >>> .title-text {
    font-size: 0.6em;
  }

  .table-card-row .table-container-card .summary-row >>> .number-text {
    font-size: 0.7em;
  }

  .table-card-row .table-container-card .summary-row >>> .img-eql {
    width: 75%;
    height: 20px;
    margin-left: 20px;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .filter-cards-row {
    min-height: 360px;
    padding: 20px 0px;
    margin: 5px;
    width: 100%;
  }

  .filter-cards-row .date-filter-row {
    width: 100%;
  }

  .table-card-row .table-container-card .summary-row >>> .title-text {
    font-size: 0.9em;
  }

  .table-card-row .table-container-card .summary-row >>> .number-text {
    font-size: 1em;
  }

  .table-card-row .table-container-card .summary-row >>> .img-eql {
    width: auto;
    margin-left: 0;
  }
}
</style>
